import React, { Component } from 'react'
import AuthenticatedPage from './components/AuthenticatedPage'
import Page from './components/Page'
import { observer, inject } from 'mobx-react'
import './App.css'
import { AuthStore } from './stores/AuthStore'
import { BrowserRouter } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'

// Configure this here for now since tree-shaking might kill it
TopBarProgress.config({
  barColors: {
    '0': 'rgba(39,  71, 78, .7)',
    '.3': 'rgba(52,  128, 196, .7)',
    '1.0': 'rgba(180, 194, 146,  .7)'
  },
  barThickness: 4,
  shadowBlur: 3
})

interface Props {
  authStore?: AuthStore
}

@inject('authStore')
@observer
class App extends Component<Props> {
  getJwt = () => {
    return this.props.authStore.getJwt
  }

  render() {
    return this.getJwt() ? (
      <BrowserRouter>
        <AuthenticatedPage />
      </BrowserRouter>
    ) : (
      <Page authStore={this.props.authStore} />
    )
  }
}

export default App
