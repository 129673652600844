import { observable, action } from 'mobx'

export class AuthStore {
  @observable jwt: string = null
  @observable name: string = null
  @observable profileImage: string = null
  @observable email: string = null

  get getJwt() {
    return this.jwt
  }

  get getName() {
    return this.name
  }

  get getProfileImage() {
    return this.profileImage
  }

  @action.bound
  setJwt(jwt) {
    this.jwt = jwt
  }

  @action.bound
  clearJwt() {
    this.jwt = null
    this.name = null
    this.profileImage = null
    this.email = null
  }

  @action.bound
  setName(name: string) {
    this.name = name
  }

  @action.bound
  setProfileImage(imageLink: string) {
    this.profileImage = imageLink
  }

  @action.bound
  setEmail(email: string) {
    this.email = email
  }
}
