import * as React from 'react'
import { Table, Icon, Divider, Popconfirm, message } from 'antd'
import moment from 'moment'
import numbro from 'numbro'
import { deleteBill } from '../../api/Transaction'
import { inject, observer } from 'mobx-react'
import { BillsStore } from '../../stores/BillsStore'

interface Props {
  billsStore?: BillsStore
}

const { Column } = Table

const BillTable: React.FunctionComponent<Props> = ({ billsStore }) => {
  const onFilter = (value, record) => record.paid.toString() === value

  const formatAmount = amount => numbro(amount).formatCurrency({ mantissa: 2 })

  const confirm = async bill => {
    await deleteBill(bill)
    billsStore.deleteBill(bill)
    message.success('Bill successfully deleted.')
  }

  return (
    <div>
      <div className="table-operations">
        <Table
          size="small"
          dataSource={billsStore.bills.slice()}
          pagination={{ pageSize: 15 }}
          rowKey={(record: any) => record.id}
        >
          <Column width={300} title="Source" dataIndex="source" />
          <Column
            width={150}
            title="Amount"
            dataIndex="amount"
            render={formatAmount}
          />
          <Column
            width={200}
            title="Due Date"
            dataIndex="dueDate"
            render={dueDate => (
              <span>{moment(dueDate).format('DD-MM-YYYY')}</span>
            )}
          />
          <Column
            title="Paid"
            dataIndex="paid"
            width={25}
            filterMultiple={false}
            filters={[
              { text: 'Paid', value: 'true' },
              { text: 'Unpaid', value: 'false' }
            ]}
            onFilter={onFilter}
            render={paid => (
              <span>
                {paid ? (
                  <Icon
                    type="check-circle"
                    theme="twoTone"
                    twoToneColor="#52c41a"
                  />
                ) : (
                  <Icon
                    type="close-circle"
                    theme="twoTone"
                    twoToneColor="#eb2f96"
                  />
                )}
              </span>
            )}
          />
          <Column
            title="Action"
            key="action"
            width={100}
            render={(text, record) => (
              <span>
                {/* <a href="#">Edit</a>
                <Divider type="vertical" /> */}
                <Popconfirm
                  title="Delete this bill?"
                  onConfirm={() => confirm(record)}
                  okText="Yes"
                  cancelText="No"
                >
                  <a href="#">Delete</a>
                </Popconfirm>
              </span>
            )}
          />
        </Table>
      </div>
    </div>
  )
}

export default inject('billsStore')(observer(BillTable))
