import * as React from 'react'
import { Layout, Typography } from 'antd'

const NotificationSettings: React.FunctionComponent = () => {
  return (
    <Layout.Content style={{ padding: '0 24px', minHeight: 280 }}>
      <Typography>
        <Typography.Title level={4}>Notification Settings</Typography.Title>
      </Typography>
    </Layout.Content>
  )
}

export default NotificationSettings
