import * as React from 'react'
import { Layout, Menu, Breadcrumb, Icon, Typography } from 'antd'
import { Route, RouteComponentProps, Switch } from 'react-router'
import BasicSettings from './BasicSettings'
import { Link } from 'react-router-dom'
import NotificationSettings from './NotificationSettings'

const { SubMenu } = Menu
const { Header, Content, Footer, Sider } = Layout

const Settings: React.FunctionComponent<RouteComponentProps> = props => {
  return (
    <div className="app-section-container">
      <section className="app-section">
        <Typography>
          <Typography.Title level={3}>Settings</Typography.Title>
        </Typography>
      </section>
      <Layout className="app-section-content">
        <Layout style={{ padding: '24px 0', background: '#fff' }}>
          <Sider width={200} style={{ background: '#fff' }}>
            <Menu defaultSelectedKeys={[props.location.pathname]} mode="inline">
              <Menu.Item key={props.match.url}>
                <Link to={props.match.url}>
                  <Icon type="setting" />
                  Basic Settings
                </Link>
              </Menu.Item>
              <Menu.Item key={`${props.match.url}/notification`}>
                <Link to={`${props.match.url}/notification`}>
                  <Icon type="mail" />
                  Notification Settings
                </Link>
              </Menu.Item>
            </Menu>
          </Sider>
          <Route
            path={`${props.match.path}/notification`}
            component={NotificationSettings}
          />
          <Route exact path={props.match.path} component={BasicSettings} />
        </Layout>
      </Layout>
    </div>
  )
}

export default Settings
