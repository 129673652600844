import * as React from 'react'
import { Menu, Icon, Layout } from 'antd'
import {
  Link,
  withRouter,
  RouteComponentProps,
  NavLink
} from 'react-router-dom'
import './Sidebar.scss'
import { observer, inject } from 'mobx-react'
import { AuthStore } from '../../stores/AuthStore'

export interface Props {
  authStore?: AuthStore
}

const Sidebar: React.FC<Props> = props => {
  return (
    <div className="sidebar">
      <div className="header">BEAGLE</div>
      <img src={props.authStore.profileImage} className="profile-image" />
      <div className="name">Hi {props.authStore.name}!</div>
      <div>{props.authStore.email}</div>
      <div className="nav">
        <NavLink className="nav-button" activeClassName="active" exact to="/">
          <Icon type="desktop" />
          <span>Dashboard</span>
        </NavLink>

        <NavLink className="nav-button" activeClassName="active" to="/bills">
          <Icon type="dollar" />
          <span>Bills</span>
        </NavLink>
        <NavLink className="nav-button" activeClassName="active" to="/settings">
          <Icon type="setting" />
          <span>Settings</span>
        </NavLink>
        <NavLink className="nav-button" activeClassName="active" to="/add">
          <Icon type="plus-circle" />
          <span>Add</span>
        </NavLink>
      </div>
    </div>
  )
}

export default inject('authStore')(observer(Sidebar))
