import * as React from 'react'
import { Bill } from '../../models/Bill'
import { List, Checkbox } from 'antd'
import moment from 'moment'
import numbro from 'numbro'
import { useTransition, animated } from 'react-spring'

export interface Props {
  item: Bill
  updateBill: (billId: Bill) => void
}

export const BillListItem: React.FunctionComponent<Props> = ({
  item,
  updateBill
}) => {
  const [checked, setChecked] = React.useState<boolean>(item.paid)
  const [showPaidButton, setShowPaidButton] = React.useState<boolean>(false)

  const transitions = useTransition(showPaidButton, null, {
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  React.useEffect(() => {
    setChecked(item.paid)
  }, [item])

  const dueDate = moment(item.dueDate)
  const dayDue = dueDate.format('DD')
  const monthDue = dueDate.format('MMM')

  const onChange = e => {
    const updatedBill = { ...item }
    updatedBill.paid = !checked
    updateBill(updatedBill)
    setChecked(!checked)
  }

  const onMouseEnter = () => {
    setShowPaidButton(true)
  }

  const onMouseLeave = () => {
    setShowPaidButton(false)
  }

  return (
    <List.Item
      className={`bills-list item ${checked ? 'paid' : ''}`}
      // actions={showPaidButton && [<Checkbox checked={checked} onChange={onChange} />]}
      actions={[<Checkbox key={1} checked={checked} onChange={onChange} />]}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="bill-due-date">
        <div>{dayDue}</div>
        <div>{monthDue.toUpperCase()}</div>
      </div>
      <div className="bill-source">{item.source}</div>
      <div className="bill-amount">
        {numbro(item.amount).formatCurrency({ mantissa: 2 })}
      </div>
    </List.Item>
  )
}
