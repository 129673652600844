import * as React from 'react'
import GoogleLogin from 'react-google-login'
import { verifyToken } from '../api/Authentication'
import { Button, Spin } from 'antd'
import './Page.scss'

interface PageProps {
  authStore: any
}

export const Page = (props: PageProps) => {
  const [loginSpinner, setLoginSpinner] = React.useState(false)

  const startRequest = () => {
    console.log('i started my request')
    setLoginSpinner(true)
  }

  const responseGoogle = async res => {
    console.log(res)
    setLoginSpinner(true)
    try {
      const response = await verifyToken(res.tokenId)
      if (response.status === 200) {
        props.authStore.setJwt(res.tokenId)
        props.authStore.setName(res.profileObj.givenName)
        props.authStore.setProfileImage(res.profileObj.imageUrl)
        props.authStore.setEmail(res.profileObj.email)
      }
    } catch (e) {
      console.error('Failed to login: ' + e)
    } finally {
      setLoginSpinner(false)
    }
  }

  const responseGoogleFailure = res => {
    setLoginSpinner(false)
  }

  const handleSigninSuccess = res => {
    setLoginSpinner(true)
  }

  return (
    <div className="login-page">
      <div className="login-wrapper">
        <div className="splash-text">BEAGLE</div>
        <GoogleLogin
          clientId="589879195473-ulna1t74m9gqrjai0370cds5b6f380i5.apps.googleusercontent.com"
          className="login-button"
          onSuccess={responseGoogle}
          onFailure={responseGoogleFailure}
          onRequest={startRequest}
          isSignedIn={true}
          render={renderProps =>
            loginSpinner ? (
              <Spin />
            ) : (
              <Button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                Login wtih Google
              </Button>
            )
          }
        />
      </div>
    </div>
  )
}

export default Page
