import { observable, action, runInAction } from 'mobx'
import { retrieveBillSources } from '../api/User'

enum BillSourceLoadingState {
  LOADING = 'loading',
  DONE = 'done',
  ERROR = 'error'
}

export class UserStore {
  @observable billSources: string[] = []
  state: BillSourceLoadingState

  @action
  async fetchBillSources() {
    this.billSources = []
    this.state = BillSourceLoadingState.LOADING
    try {
      const bills = await retrieveBillSources()
      this.billSources = bills.data
      this.state = BillSourceLoadingState.DONE
    } catch (error) {
      runInAction(() => {
        this.state = BillSourceLoadingState.ERROR
      })
    }
  }
}
