import * as React from 'react'
import { Layout, Typography } from 'antd'
import BillAddForm from './Bills/BillAddForm'
import { inject, observer } from 'mobx-react'
import { UserStore } from '../stores/UserStore'

export interface AddBillProps {
  userStore: UserStore
}

@inject('userStore')
@observer
export default class AddBill extends React.Component<AddBillProps, any> {
  state = {
    billSources: []
  }

  async componentDidMount() {
    // Kind of messy, could just return it?
    await this.props.userStore.fetchBillSources()
    this.setState({ billSources: this.props.userStore.billSources })
  }

  render() {
    return (
      <div className="app-section-container">
        <section className="app-section">
          <Typography>
            <Typography.Title level={3}>Add expense</Typography.Title>
          </Typography>
        </section>
        <Layout className="app-section-content">
          <BillAddForm billSources={this.state.billSources} />
        </Layout>
      </div>
    )
  }
}
