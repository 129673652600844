import { stores } from './../stores/index'
import axios from 'axios'

export async function retrieveBillSources() {
  const jwt = stores.authStore.getJwt
  try {
    const response = await axios.get('/api/user/bill-sources', {
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Cache-Control': 'no-cache'
      }
    })
    return response
  } catch (e) {
    return Promise.reject(e)
  }
}
