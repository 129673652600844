import * as React from 'react'
import moment from 'moment'
import { Statistic, Card } from 'antd'
import { inject, observer } from 'mobx-react'
import { BillsStore } from '../../../stores/BillsStore'
import TotalAmountDue from './TotalAmountDue'
import { Grid, Row, Col } from 'react-flexbox-grid'
import './DashboardCards.scss'

interface Props {
  billsStore?: BillsStore
}

@inject('billsStore')
@observer
export default class DashboardCards extends React.Component<Props, any> {
  calculateDaysUntilDue() {
    // Might not need this if we sort after we fetch
    const sorted = this.props.billsStore.bills.slice().sort((a, b) => {
      if (moment(a.dueDate).valueOf() > moment(b.dueDate).valueOf()) {
        return -1
      }

      return 1
    })

    const nextDueBill =
      sorted.filter(bill => bill.paid === false).reverse()[0] || null

    if (nextDueBill) {
      const daysUntilDue = moment(nextDueBill.dueDate).from(
        new Date().setHours(0, 0, 0, 0)
      )
      // const daysUntilDue = moment().diff(moment(nextDueBill.dueDate), 'days')
      return daysUntilDue
    }

    return -1
  }

  render() {
    const daysUntilDue = this.calculateDaysUntilDue()

    if (daysUntilDue === -1) {
    }

    return (
      <div className="dashboard-header">
        <div className="dashboard-header-card">
          <div className="header">Next bill due</div>
          <div className="content">{daysUntilDue}</div>
        </div>

        <TotalAmountDue
          period={'month'}
          billList={this.props.billsStore.bills}
        />
        {/* <Col span={8}>
            <Card title="Net income this month">Card content</Card>
          </Col> */}
      </div>
    )
  }
}
