import { Bill } from './../models/Bill'
import axios from 'axios'
import { Transaction } from '../models/Bill'
import { stores } from '../stores'

export async function submitTransaction(transaction: Transaction) {
  const jwt = stores.authStore.getJwt
  try {
    const response = await axios.post('/api/transactions/bill', transaction, {
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Cache-Control': 'no-cache'
      }
    })
    return response
  } catch (e) {
    return Promise.reject(e)
  }
}

export async function getBills() {
  const jwt = stores.authStore.getJwt
  try {
    const response = await axios.get('/api/transactions/bills', {
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Cache-Control': 'no-cache'
      }
    })
    return response
  } catch (e) {
    return Promise.reject(e)
  }
}

export async function updateBill(bill: Bill) {
  const jwt = stores.authStore.getJwt
  try {
    const response = await axios.put(
      `/api/transactions/bill/${bill.id}`,
      bill,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Cache-Control': 'no-cache'
        }
      }
    )
    return response
  } catch (e) {
    return Promise.reject(e)
  }
}

export async function deleteBill(bill: Bill) {
  const jwt = stores.authStore.getJwt
  try {
    const response = await axios.delete(`/api/transactions/bill/${bill.id}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Cache-Control': 'no-cache'
      }
    })
    return response
  } catch (e) {
    return Promise.reject(e)
  }
}
