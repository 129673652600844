import dotenv from 'dotenv'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { stores } from './stores'
import { Provider } from 'mobx-react'
import { BreakpointProvider } from 'react-socks'

dotenv.config()

ReactDOM.render(
  <BreakpointProvider>
    <Provider {...stores}>
      <App />
    </Provider>
  </BreakpointProvider>,
  document.getElementById('root')
)
