import * as React from 'react'
import { Statistic, Card } from 'antd'
import { Bill } from '../../../models/Bill'
import moment from 'moment'
import numbro from 'numbro'

interface ITotalAmountDueProps {
  period: string
  billList: Bill[]
}

const TotalAmountDue: React.FunctionComponent<ITotalAmountDueProps> = props => {
  const amountDue: number = React.useMemo(() => {
    return props.billList
      .filter(bill => moment(bill.dueDate).month() === moment().month())
      .reduce((acc, cur) => acc + cur.amount, 0)
  }, [props])

  return (
    <div className="dashboard-header-card">
      <div className="header">{`Total amount due this ${props.period}`}</div>
      <div className="content">
        {numbro(amountDue).formatCurrency({ mantissa: 2 })}
      </div>
    </div>
  )
}

export default TotalAmountDue
