import * as React from 'react'
import DashboardCards from './DashboardCards/DashboardCards'
import BillList from '../Bills/BillList'
import Calendar from './Calendar'
import './Dashboard.scss'

const Dashboard: React.FC = () => {
  return (
    <div className="content-wrapper">
      <div className="section-wrapper">
        <DashboardCards />
        <div className="dashboard">
          <section className="app-section">
            <section className="bill-list">
              <BillList />
            </section>
          </section>
        </div>
      </div>
      <div className="right-panel">
        <Calendar />
      </div>
    </div>
  )
}

export default Dashboard
