import * as React from 'react'
import {
  Form,
  InputNumber,
  DatePicker,
  Button,
  AutoComplete,
  Radio
} from 'antd'
import moment from 'moment'
import { submitTransaction } from '../../api/Transaction'
import { Transaction } from '../../models/Bill'

export interface BillSource {
  source: string
  timesUsed: number
}

export interface BillAddProps {
  form: any
  billSources: BillSource[]
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 7 }
  }
}

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 7
    }
  }
}

const BillAddForm: React.FC<BillAddProps> = props => {
  const [isRepeatable] = React.useState(false)
  const { getFieldDecorator, validateFields, resetFields } = props.form

  const handleSubmit = e => {
    e.preventDefault()
    validateFields((err, values) => {
      if (!err) {
        const dueDate = moment(values.dueDate).format('YYYY-MM-DD')
        const billToAdd: Transaction = {
          amount: values.amount,
          dueDate,
          expenseType: values.expenseType,
          source: values.source
        }
        submitTransaction(billToAdd)
        resetFields()
      }
    })
  }

  const disabledDate = current => {
    // Can not select days before today and today
    return current && current < moment().endOf('day')
  }

  const renderRepeatable = () => {
    return (
      <Form.Item>
        {getFieldDecorator('repeatedDate', {
          rules: [{ required: true, message: 'A due date is required.' }]
        })(
          <DatePicker
            placeholder="Due date"
            format="YYYY-MM-DD"
            disabledDate={disabledDate}
          />
        )}
      </Form.Item>
    )
  }

  const dataSource =
    props.billSources && props.billSources.map(billSource => billSource.source)

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item label="Type">
        {getFieldDecorator('expenseType', {
          rules: [{ required: true, message: 'Please input a source!' }],
          initialValue: 'bill'
        })(
          <Radio.Group defaultValue="bill" buttonStyle="solid" size="small">
            <Radio.Button value="bill">Bill</Radio.Button>
            {/* <Radio.Button value="income">Income</Radio.Button> */}
          </Radio.Group>
        )}
      </Form.Item>

      <Form.Item label="Expense / income source">
        {getFieldDecorator('source', {
          rules: [{ required: true, message: 'A source is required.' }]
        })(
          <AutoComplete
            dataSource={dataSource}
            placeholder="Source"
            filterOption={(inputValue, option: any) =>
              option.props.children
                .toUpperCase()
                .indexOf(inputValue.toUpperCase()) !== -1
            }
          />
        )}
      </Form.Item>
      <Form.Item label="Amount">
        {getFieldDecorator('amount', {
          rules: [{ required: true, message: 'The amount value is required.' }]
        })(
          <InputNumber
            min={0}
            formatter={value =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            // onChange={() => {}}
          />
        )}
      </Form.Item>
      <Form.Item label="Due date">
        {getFieldDecorator('dueDate', {
          rules: [{ required: true, message: 'A due date is required.' }]
        })(
          <DatePicker
            placeholder="Due date"
            format="YYYY-MM-DD"
            disabledDate={disabledDate}
          />
        )}
      </Form.Item>
      {isRepeatable ? renderRepeatable() : null}
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Add
        </Button>
      </Form.Item>
    </Form>
  )
}

export default Form.create<BillAddProps>({ name: 'bill_add' })(BillAddForm)

// Repeatable toggle?

{
  /* <Form.Item label="Repeatable">
  {getFieldDecorator('repeatable')(
    <Select disabled placeholder="Please select if recurring">
      <Select.Option value="na">N/A</Select.Option>
      <Select.Option value="date">Monthly on the date</Select.Option>
      <Select.Option value="days">Every X days</Select.Option>
      <Select.Option value="months">Every X months</Select.Option>
      <Select.Option value="quarter">Every quarter</Select.Option>
    </Select>
  )}
</Form.Item> */
}

// Determines if bill or income
{
  /* <Form.Item label="Type">
  {getFieldDecorator('expenseType', {
    rules: [{ required: true, message: 'Please input a source!' }],
    initialValue: 'bill'
  })(
    <Radio.Group defaultValue="bill" buttonStyle="solid" size="small">
      <Radio.Button value="bill">Bill</Radio.Button>
      <Radio.Button value="income">Income</Radio.Button>
    </Radio.Group>
  )}
</Form.Item> */
}
