import * as React from 'react'
import { Moment } from 'moment'
import { Calendar as AntdCalendar } from 'antd'
import moment from 'moment'
import { BillsStore } from '../../stores/BillsStore'
import { inject, observer } from 'mobx-react'
import { Bill } from '../../models/Bill'
import { toJS } from 'mobx'

interface ICalendarProps {
  billsStore?: BillsStore
}

const Calendar: React.FunctionComponent<ICalendarProps> = ({ billsStore }) => {
  const bills = toJS(billsStore.bills)

  const isBillDate = date => {
    const bill = bills.slice().find((b: Bill) => {
      const bill = moment(b.dueDate)

      const billDate = `${bill.year()}${bill.month()}${bill.date()}`
      const calendarDate = `${date.year()}${date.month()}${date.date()}`

      if (billDate === calendarDate) {
        return true
      }
      return undefined
    })

    if (bill && bill.paid) {
      return 'paid'
    } else if (bill && !bill.paid) {
      return 'unpaid'
    }
    return ''
  }

  const calendarDateState = date => {
    if (date.date() === moment().date()) {
      return 'today'
    }

    const result = isBillDate(date)
    return result
  }

  const dateCellRender = (value: Moment) => {
    const date = value.date()
    return <div className={`date ${calendarDateState(value)}`}>{date}</div>
  }

  return <AntdCalendar fullscreen={false} dateFullCellRender={dateCellRender} />
}

export default inject('billsStore')(observer(Calendar))
