import * as React from 'react'
import { Layout, Typography } from 'antd'
import BillTable from './BillTable'
import { inject, observer } from 'mobx-react'
import { BillsStore } from '../../stores/BillsStore'
import TopBarProgress from 'react-topbar-progress-indicator'

interface Props {
  billsStore: BillsStore
}

const BillListPage: React.FC<Props> = ({ billsStore }) => {
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      await billsStore.fetchBills()
      setLoading(false)
    }

    fetchData()
  }, [])

  return (
    <div className="app-section-container">
      {loading && <TopBarProgress />}
      <section className="app-section">
        <Typography>
          <Typography.Title level={3}>Bills</Typography.Title>
        </Typography>
      </section>
      <Layout className="app-section-content">
        <Layout style={{ padding: '24px', background: '#fff' }}>
          <BillTable />
        </Layout>
      </Layout>
    </div>
  )
}

export default inject('billsStore')(observer(BillListPage))
