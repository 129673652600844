import axios from 'axios'

export async function verifyToken(token) {
  try {
    const response = await axios.post(
      '/api/auth/verify',
      {
        token
      },
      {
        headers: { 'Cache-Control': 'no-cache' }
      }
    )
    console.log(response)
    return response
  } catch (e) {
    return Promise.reject(`Failed to verify token: ${e}`)
  }
}
