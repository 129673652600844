import * as mobx from 'mobx'
import { observable, runInAction, action } from 'mobx'
import { getBills, deleteBill } from '../api/Transaction'
import moment from 'moment'
import { Bill } from '../models/Bill'

mobx.configure({ enforceActions: 'observed' })

enum BillsLoadingState {
  LOADING = 'loading',
  DONE = 'done',
  ERROR = 'error'
}

export class BillsStore {
  @observable bills: any = []
  @observable state: BillsLoadingState = BillsLoadingState.LOADING

  @action async fetchBills() {
    //this.bills.clear()
    this.state = BillsLoadingState.LOADING
    try {
      const bills = await getBills()
      runInAction(() => {
        this.state = BillsLoadingState.DONE
        const sorted = bills.data.sort((a, b) => {
          if (moment(a.dueDate).valueOf() > moment(b.dueDate).valueOf()) {
            return -1
          }

          return 1
        })
        this.bills.replace(sorted)
      })
    } catch (error) {
      runInAction(() => {
        this.state = BillsLoadingState.ERROR
      })
    }
  }

  @action setBillPaid(bill: Bill) {
    const billToUpdate = this.bills.find(b => b.id === bill.id)
    billToUpdate.paid = bill.paid
  }

  @action deleteBill(bill: Bill) {
    const toBeRemoved = this.bills.find(b => b.id === bill.id)
    this.bills.remove(toBeRemoved)
  }
}
