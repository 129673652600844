import React, { useContext } from 'react'
import './AuthenticatedPage.scss'
import Sidebar from './Sidebar/Sidebar'
import { inject, observer } from 'mobx-react'
import { AuthStore } from '../stores/AuthStore'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Dashboard from './Dashboard/Dashboard'
import AddBill from './AddBill'
import Settings from './Settings/Settings'
import BillListPage from './BillListPage/BillListPage'
import { useTransition, animated } from 'react-spring'
import { __RouterContext } from 'react-router'

interface Props {
  authStore?: AuthStore
}

const AuthenticatedPage: React.FC = () => {
  const { location } = useContext(__RouterContext)

  const transitions = useTransition(location, location => location.pathname, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  return (
    <div className="app">
      <div className="authenticated-wrapper">
        <div className="sidebar-container">
          <Sidebar />
        </div>
        <div className="content">
          <Switch>
            <Route path="/" exact component={Dashboard} />
            <Route path="/settings" component={Settings} />
            <Route path="/bills" component={BillListPage} />
            <Route path="/add" component={AddBill} />
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default AuthenticatedPage
