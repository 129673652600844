import * as React from 'react'
import { List } from 'antd'
import { observer, inject } from 'mobx-react'
import { BillsStore } from '../../stores/BillsStore'
import { Bill } from '../../models/Bill'
import { BillListItem } from './BillListItem'
import { updateBill } from '../../api/Transaction'
import TopBarProgress from 'react-topbar-progress-indicator'
import './BillList.scss'

interface Props {
  billsStore?: BillsStore
}

const BillList: React.FunctionComponent<Props> = ({ billsStore }) => {
  const [billsLoading, setBillsLoading] = React.useState(false)

  React.useEffect(() => {
    const fetchData = async () => {
      setBillsLoading(true)
      await billsStore.fetchBills()
      setBillsLoading(false)
    }

    fetchData()
  }, [])

  const updateBillStore = (bill: Bill) => {
    updateBill(bill)
    billsStore.setBillPaid(bill)
  }

  const unpaidBills = billsStore.bills.filter(bill => !bill.paid).reverse()

  const paidBills = billsStore.bills
    .filter(bill => bill.paid)
    .slice(0, 5)
    .reverse()

  return (
    <div className="bill">
      {billsLoading && <TopBarProgress />}
      <List
        header={<div className="list-header">UPCOMING BILLS</div>}
        bordered={false}
        itemLayout="horizontal"
        className="bills-list paid-list"
        dataSource={unpaidBills}
        renderItem={(item: Bill) => (
          <BillListItem item={item} updateBill={updateBillStore} />
        )}
      />
      <List
        header={<div className="list-header">LAST 5 PAID BILLS</div>}
        bordered={false}
        itemLayout="horizontal"
        className="bills-list"
        dataSource={paidBills}
        renderItem={(item: Bill) => (
          <BillListItem item={item} updateBill={updateBillStore} />
        )}
      />
    </div>
  )
}

export default inject('billsStore')(observer(BillList))
